<!--
 * @Author: Leo
 * @Description: 优才-方案审核
-->
<template>
    <div v-loading="isLoading" class="gifted">
        <!-- 基本信息 -->
        <!-- <Basic :dataDetail="dataDetail[0].data"/> -->
        <!-- 打分情况 -->
        <!-- <Scoring :scoringData="data[1]"/> -->
        <!-- 资料提供情况 -->
        <!-- <Provision :provisionData="data[2]"/> -->
        <!-- 文书方案 -->
        <!-- <Document :documentData="data[3]"/> -->
        <!-- 同行推荐信 -->
        <!-- <Letter :letterData="data[4]"/> -->
        <!-- 赴港计划 -->
        <!-- <Plan :planData="data[5]"></Plan> -->
        <!-- 学位方案 -->
        <!-- <Degree :degreeData="data[6]"></Degree> -->
        <!-- 线上递交问题 -->
        <!-- <Online :onlineData="data[7]"></Online> -->
        <!-- 全套终审 -->
        <!-- <Complete :completeData="data[8]"/> -->
        <div  v-for="(item, index) in dataDetail" :key="index" >
            <component :is="item.component" :dataDetail="item.data"></component>
        </div>

    </div>
</template>

<script>
import Basic from './Basic/Basic.vue'
import Scoring from './Scoring/Scoring.vue'
import Provision from './Provision/Provision.vue'
import Document from './Document/Document.vue'
import Letter from './Letter/Letter.vue'
import Plan from './Plan/Plan.vue'
import Degree from './Degree/Degree'
import Online from './Online/Online.vue'
import Complete from './Complete/Complete.vue'
import { getSchemeReview, getUserMenu } from '@/api/schemeReview.js'
    export default {
        name: 'SchemeReviewGifted',
        components: {
            Basic,
            Scoring,
            Provision,
            Document,
            Letter,
            Plan,
            Degree,
            Online,
            Complete
        },
        data() {
            return {
                dataDetail: [],
                isLoading: false
            }
        },
        created () {
            this.getToken()
            this.getUserMenu()
            this.getData()
        },
        methods: {
            // 获取登录态
            getToken() {
                localStorage.clear()
                localStorage.setItem("token", this.$route.query.crm_token);
                this.$store.commit("setToken")
            },
            // 获取数据
            async getData () {
                this.isLoading = true
                const { data, code } = await getSchemeReview({order_id: this.$route.params.id})
                if (code == 200) {
                    this.dataDetail = data
                    this.isLoading = false
                    console.log('dataDetail', this.dataDetail);
                }
            },
            // 获取支持展示按钮权限数据
            async getUserMenu() {
                const {data ,code} = await getUserMenu({
                    terminal: "service"
                });
                if (code == 200) {
                    localStorage.setItem("permission", JSON.stringify(data));
                }
            }
        },
    }
</script>

<style lang="less" scoped>
.gifted{
    padding-bottom: 50px;
    >div{
        margin-top: 20px;
    }
}
</style>
