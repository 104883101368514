<template>
    <div>
        <div v-for="(item, i) in editData" :key="i" class="document">
            <!-- 文书方案数据展示 -->
            <div class="show-data">
                <div>学校{{ item.proportion }}：{{ item.college }}</div>
                <div class="content">
                    <div style="width: 350px;">是否有学位：{{ item.background }}<span v-if="item.degree_type">（{{item.degree_type}}）</span></div>
                </div>
            </div>

            <!-- 文书方案编辑区 -->
            <div class="edit-data">
                <div>
                    <div>{{item.title}}：</div>
                    <el-input
                        style="margin-top: 10px;"
                        type="textarea"
                        placeholder="请输入"
                        v-model="item.remark"
                        maxlength="2000"
                        show-word-limit
                    />
                    <AddAnnex
                        :echoFileData="item.file"
                        @confirm="addAnnexConfirm($event, i)"
                        @fileClose="fileClose($event, i)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AddAnnex from '../../C/AddAnnex.vue'
import { degreeUpdate } from '@/api/schemeReview.js'
    export default {
        components: {
            AddAnnex
        },
        props: {
            // 接收的数据
            editDegreeData: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                editData: [],
                // annexList : [],
            }
        },
        mounted () {
            this.editData = this.editDegreeData && JSON.parse(JSON.stringify(this.editDegreeData))
        },
        methods: {
            // 添加附件确认后回传数据
            addAnnexConfirm (data, i, j) {
                this.editData[i].file = data
            },
            // 删除上传的附件
            fileClose(ele ,i) {
                // 不影响原数据
                let arr = JSON.parse(JSON.stringify(this.editData))
                // 循环找出点击删除按钮的元素后，删除对应的元素
                arr.forEach((item, j) => {
                    item.file.forEach((data, k) => {
                        if (data.fileList[0] && data.fileList[0].fileUrl == ele.fileList[0].fileUrl) {
                            arr[j].file.splice(k, 1)
                        }
                    })
                })
                this.editData = arr
            },
            // 保存数据
            async saveInfo () {
                const params = {
                    order_id: this.$route.params.id,
                    question:[]
                }
                this.editData.forEach(item => {
                    params.question.push(item)
                })
                const { code } = await degreeUpdate(params)
                if (code == 200) {
                    this.$message({
                        message: '编辑成功',
                        type: 'success'
                    });
                }
            }
        },
    }
</script>

<style lang="less" scoped>
.document{
    border-bottom: 1px #cdcdcd solid;
    margin-top: 15px;
    .show-data{
        >div{
            margin-bottom: 15px;
        }
        .content{
            display: flex;
            >div{
                overflow:hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
    .edit-data{
        margin-top: 20px;
        >div{
            margin-bottom: 10px;
        }
    }

    a{
    color: #409eff;
    font-size: 12px;
    margin-right: 20px;
}
    .remarks{
        >div{
            margin-bottom: 20px;
        }
    }
}
.document:last-child{
    border: none;
}
</style>
