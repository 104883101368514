<template>
    <div>
        <div v-if="!documentDetailData.length" class="N-documents">暂无数据</div>
        <div v-else class="document">
            <div v-for="(item, i) in documentDetailData" :key="i" class="document">
                <div class="show-data">
                    <!-- deleted == 1，展示删除的样式。is_famous_enterprise == 1，展示名企标签 -->
                    <div>雇主{{ item.deleted == 1? '【已删除】': item.proportion }}： <span :style="{ textDecoration: item.deleted == 1 ? 'line-through' : 'none'}">{{ item.company_name }}</span> <span style="color: #97ca44;">{{ item.is_famous_enterprise == 1 ? '（名企）': ''}}</span></div>
                    <div class="content" style="width: 900px;">高管条件：{{ item.executives_certificate }}</div>
                    <div class="content" style="width: 900px;">文书方案及待确认情况：{{ item.work_certificate }}</div>
                </div>

                <div class="edit-data">
                    <div v-for="(childData, j) in item.audit_document" :key="j" style="margin-top: 30px;">
                        <History
                            :title="`${childData.title}：`"
                            :content="childData.remark"
                            :tabaData="childData.edit"
                        />
                        <span v-for="(fileData, index) in childData.file" :key="index">
                            <a v-if="fileData.fileList.length" target='_blank' type="text" :href="`${$utils.toOss(fileData.fileList[0].fileUrl)}`">{{ fileData.name }}</a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import History from '../../C/History'
    export default {
        components: {
            History
        },
        props: {
            // 接收的数据
            documentDetailData: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
            }
        },
    }
</script>

<style lang="less" scoped>.document{
    border-bottom: 1px #cdcdcd solid;
    margin-top: 15px;
    .show-data{
        >div{
            margin-bottom: 15px;
        }
        .content{
            overflow:hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .edit-data{
        margin-top: 30px;
        >div{
            margin-bottom: 10px;
        }
    }

    a{
    color: #409eff;
    font-size: 12px;
    margin-right: 20px;
}
    .remarks{
        >div{
            margin-bottom: 20px;
        }
    }
}
.N-documents{
    text-align: center;
    height: 50px;
    line-height: 50px;
}
.document:last-child{
    border: none;
}
</style>
