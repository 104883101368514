<template>
    <div>
        <div v-for="(item, i) in editData" :key="i" class="document">
            <!-- 文书方案数据展示 -->
            <div class="show-data">
                <!-- deleted == 1，展示删除的样式。is_famous_enterprise == 1，展示名企标签 -->
                <div>雇主{{ item.deleted == 1? '【已删除】': item.proportion }}： <span :style="{ textDecoration: item.deleted == 1 ? 'line-through' : 'none'}">{{ item.company_name }}</span> <span style="color: #97ca44;">{{ item.is_famous_enterprise == 1 ? '（名企）': ''}}</span></div>
                <div class="content" style="width: 900px;">高管条件：{{ item.executives_certificate }}</div>
                <div class="content" style="width: 900px;">文书方案及待确认情况：{{ item.work_certificate }}</div>
            </div>
            <!-- 文书方案编辑区 -->
            <div class="edit-data">
                <div v-for="(childData, j) in item.audit_document" :key="j">
                    <div>{{childData.title}}：</div>
                    <el-input
                        style="margin-top: 10px;"
                        type="textarea"
                        placeholder="请输入"
                        v-model="childData.remark"
                        maxlength="2000"
                        show-word-limit
                    />
                    <AddAnnex
                        :echoFileData="childData.file"
                        @confirm="addAnnexConfirm($event, i , j)"
                        @fileClose="fileClose($event, i)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AddAnnex from '../../C/AddAnnex.vue'
import { editDocument } from '@/api/schemeReview.js'
    export default {
        components: {
            AddAnnex
        },
        props: {
            // 接收的数据
            editDocumentData: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                editData: [],
            }
        },
        mounted () {
            this.editData = this.editDocumentData && JSON.parse(JSON.stringify(this.editDocumentData))
        },
        methods: {
            // 添加附件确认后回传数据
            addAnnexConfirm (data, i, j) {
                this.editData[i].audit_document[j].file = data
            },
            // 删除上传的附件
            fileClose(dataLevel ,i) {
                // 不影响原数据
                let arr = JSON.parse(JSON.stringify(this.editData))
                // 循环找出点击删除按钮的元素后，删除对应的元素
                arr[i].audit_document.forEach((item, j) => {
                    item.file.forEach((data, k) => {
                        if (data.fileList[0] && data.fileList[0].fileUrl == dataLevel.fileList[0].fileUrl) {
                            arr[i].audit_document[j].file.splice(k, 1)
                        }
                    })
                })
                this.editData = arr
            },
            // 保存数据
            async saveInfo () {
                let flag = true
                for (let i = 0; i <  this.editData.length; i++) {
                    for (let j = 0; j < this.editData[i].audit_document.length; j++) {
                        if (JSON.stringify(this.editData[i].audit_document[j]) != JSON.stringify(this.editDocumentData[i].audit_document[j])) {
                            const params = {
                                ...this.editData[i],
                            }
                            const { code } = await editDocument(params)
                            if (code != 200) {
                                // this.$message({
                                //     message: '编辑成功',
                                //     type: 'success'
                                // });
                                flag = false
                            }
                            break
                        }
                    }
                }
                // 接口轮询调用正常时，提醒一次即可
                if (flag) {
                    this.$message({
                        message: '编辑成功',
                        type: 'success'
                    });
                }





            }
        },
    }
</script>

<style lang="less" scoped>
.document{
    border-bottom: 1px #cdcdcd solid;
    margin-top: 15px;
    .show-data{
        >div{
            margin-bottom: 15px;
        }
        .content{
            overflow:hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .edit-data{
        margin-top: 30px;
        >div{
            margin-bottom: 10px;
        }
    }

    a{
    color: #409eff;
    font-size: 12px;
    margin-right: 20px;
}
    .remarks{
        >div{
            margin-bottom: 20px;
        }
    }
}
.document:last-child{
    border: none;
}
</style>
