<template>
    <div class="provision">
        <div class="remarks">
            <div>
                <!-- 备注回显与编辑操作记录 -->
                <div v-if="completeDetailData.provision == ''">某资料无法提供。</div>
                <History
                    v-else
                    title="备注/待确认信息："
                    :content="completeDetailData.remark"
                    :tabaData="completeDetailData.edit"
                />
                <!-- 添加附件 -->
                <div >
                    <span v-for="(item, index) in completeDetailData.file" :key="index">
                        <a v-if="item.fileList.length" type="text" target='_blank' :href="`${$utils.toOss(item.fileList[0].fileUrl)}`">{{ item.name }}</a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import History from '../../C/History'
    export default {
        components: {
            History
        },
        props: {
            // 接收的数据
            completeDetailData: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                tabaData: [],
                historyData: [], // 编辑操作记录数据
                annexList : [
                    {
                        "name": "我是附件1",
                        "fileList": [
                            {
                                "fileName": "111.png",
                                "fileUrl": "https://upload-cdn.galaxy-immi.com/crm/test/files/SchemeReview1681904020916.png",
                                "fileSize": 86072
                            }
                        ]
                    }
                ]
            }
        },
    }
</script>

<style lang="less" scoped>
.provision{
    a{
    color: #409eff;
    font-size: 12px;
    margin-right: 20px;
}
    .remarks{
        >div{
            margin-bottom: 20px;
        }
    }
}

</style>
