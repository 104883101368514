<template>
    <div>
        <div v-if="!degreeDetailData.length" class="N-documents">暂无数据</div>
        <div v-else class="document">
            <div v-for="(item, i) in degreeDetailData" :key="i" class="document">
                <div class="show-data">
                    <!-- deleted == 1 是已删除，is_top == 1 是百强名校 -->
                    <div>学校<span v-if="item.deleted == 1">【已删除】</span> {{ item.proportion }}：<span :style="{ textDecoration: item.deleted == 1 ? 'line-through' : 'none'}">{{ item.college }}</span> <span v-if="item.is_top == 1" style="color: #4b7902;">（百强名校）</span></div>
                    <div class="content">
                        <div style="width: 350px;">是否有学位：{{ item.background }} <span v-if="item.degree_type">（{{item.degree_type}}）</span> </div>
                    </div>
                </div>

                <div class="edit-data">
                    <div >
                        <History
                            :title="`${item.title}：`"
                            :content="item.remark"
                            :tabaData="item.edit"
                        />
                        <span v-for="(fileData, index) in item.file" :key="index">
                            <a v-if="fileData.fileList.length" target='_blank' type="text" :href="`${$utils.toOss(fileData.fileList[0].fileUrl)}`">{{ fileData.name }}</a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import History from '../../C/History'
    export default {
        components: {
            History
        },
        props: {
            // 接收的数据
            degreeDetailData: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
            }
        },
    }
</script>

<style lang="less" scoped>.document{
    border-bottom: 1px #cdcdcd solid;
    margin-top: 15px;
    .show-data{
        >div{
            margin-bottom: 15px;
        }
        .content{
            display: flex;
            >div{
                overflow:hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
    .edit-data{
        margin-top: 20px;
        >div{
            margin-bottom: 10px;
        }
    }

    a{
    color: #409eff;
    font-size: 12px;
    margin-right: 20px;
}
    .remarks{
        >div{
            margin-bottom: 20px;
        }
    }
}
.N-documents{
    text-align: center;
    height: 50px;
    line-height: 50px;
}
.document:last-child{
    border: none;
}
</style>
