<!--
 * @Author: Leo
 * @Description: 方案审核 基本信息编辑
-->
<template>
    <div class="basic">
        <!-- 基本信息 -->
        <div class="basic-info">
            <div>主申姓名：{{ editData.information_name }}</div>
            <div>文案：{{ editData.copywriters }}</div>
            <div>审案：{{ editData.chief_copywriter }}</div>
        </div>

        <!-- 备注内容 -->
        <div>
            <div>其他特殊/紧急情况备注：</div>
            <div style="margin-top: 10px;">
                <el-input
                    type="textarea"
                    placeholder="请输入"
                    v-model="editData.remark"
                    maxlength="2000"
                    show-word-limit
                />
            </div>
            <!-- 添加附件 -->
            <div>
                <AddAnnex
                    :echoFileData="editData.file"
                    @confirm="addAnnexConfirm"
                    @fileClose="fileClose"
                />
            </div>
        </div>


    </div>
</template>

<script>
import AddAnnex from '../../C/AddAnnex.vue'
import { editBasic } from '@/api/schemeReview.js'
    export default {
        components: {
            AddAnnex
        },
        props: {
            // 接收的数据
            editBasicData: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                editData: {},
            }
        },
        mounted () {
            this.editData = this.editBasicData && JSON.parse(JSON.stringify(this.editBasicData))
        },
        methods: {
            // 删除上传的附件
            fileClose(data) {
                this.editData.file.forEach((item, i) => {
                    if (item.fileList[0].fileUrl == data.fileList[0].fileUrl) {
                        this.editData.file.splice(i, 1)
                    }
                });
            },

            // 添加附件确认后回传数据
            addAnnexConfirm (data) {
                this.editData.file = data
            },

            // 保存数据
            async saveInfo () {
                const params = {
                    order_id: this.$route.params.id,
                    ...this.editData,
                }
                const { code } = await editBasic(params)
                if (code == 200) {
                    this.$message({
                        message: '编辑成功',
                        type: 'success'
                    });
                }
            }
        },
    }
</script>

<style lang="less" scoped>
.basic{
    padding: 15px 25px;
    >div {
        margin-bottom: 20px;
    }
    .basic-info{
        display: flex;
        justify-content: space-between;
        >div{
            width: 220px;
            overflow:hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
a{
    color: #409eff;
    font-size: 12px;
    margin-right: 20px;
}
</style>
