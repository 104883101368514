<template>
    <div class="scoring">
        <el-table :data="scoringDetailData.table" border style="margin-bottom: 20px;">
            <el-table-column width="150" property="type_name" label="打分项" ></el-table-column>
            <el-table-column width="100" property="age_score" label="年龄"></el-table-column>
            <el-table-column width="150" property="degree_score" label="学历">
                <template slot-scope="scope">
                    <div v-html="scope.row.degree_score"></div>
                </template>
            </el-table-column>
            <el-table-column width="150" property="school_score" label="名校背景">
                <template slot-scope="scope">
                    <div v-html="scope.row.school_score"></div>
                </template>
            </el-table-column>
            <el-table-column width="150" property="experience_score" label="工作经验"></el-table-column>
            <el-table-column width="150" property="sea_experience_score" label="海外工作经验"></el-table-column>
            <el-table-column width="150" property="enterprise_score" label="名企加分"></el-table-column>
            <el-table-column width="150" property="talents_score" label="人才清单"></el-table-column>
            <el-table-column width="300" property="language_score" label="语言分">
                <template slot-scope="scope">
                    <div v-html="scope.row.language_score"></div>
                </template>
            </el-table-column>
            <el-table-column width="300" property="background_score" label="家庭背景">
                <template slot-scope="scope">
                    <div v-html="scope.row.background_score"></div>
                </template>
            </el-table-column>
            <el-table-column width="150" property="score" label="总分"></el-table-column>
        </el-table>
        <div class="remarks">
            <div>行业定位：{{ scoringDetailData.industry }}</div>
            <div style="margin-top: 20px;">
                <!-- 备注回显与编辑操作记录 -->
                <History
                    title="备注/待确认信息："
                    :content="scoringDetailData.remark"
                    :tabaData="scoringDetailData.edit"
                />
                <!-- 添加附件 -->
                <div>
                    <span v-for="(item, index) in scoringDetailData.file" :key="index">
                        <a v-if="item.fileList.length" type="text" target='_blank' :href="`${$utils.toOss(item.fileList[0].fileUrl)}`">{{ item.name }}</a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import History from '../../C/History'
    export default {
        components: {
            History
        },
        props: {
            // 接收的数据
            scoringDetailData: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
            }
        },
    }
</script>

<style lang="less" scoped>
.scoring{
    a{
    color: #409eff;
    font-size: 12px;
    margin-right: 20px;
}
    .remarks{
        >div{
            margin-bottom: 20px;
        }
    }
}

</style>
