<template>
    <div class="letter">
        <div v-for="(item, index) in letterDetailData" :key="index" class="remarks">
            <History
                :title="`${item.title}：`"
                :content="item.remark"
                :tabaData="item.edit"
            />
            <div>
                <!-- 添加附件 -->
                <div>
                    <span v-for="(item, index) in item.file" :key="index">
                        <a target='_blank' v-if="item.fileList.length" type="text" :href="`${$utils.toOss(item.fileList[0].fileUrl)}`">{{ item.name }}</a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import History from '../../C/History'
    export default {
        components: {
            History
        },
        props: {
            // 接收的数据
            letterDetailData: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                historyData: [], // 编辑操作记录数据
                annexList : [
                    {
                        "name": "我是附件1",
                        "fileList": [
                            {
                                "fileName": "111.png",
                                "fileUrl": "https://upload-cdn.galaxy-immi.com/crm/test/files/SchemeReview1681904020916.png",
                                "fileSize": 86072
                            }
                        ]
                    }
                ]
            }
        },
    }
</script>

<style lang="less" scoped>
.letter{
    a{
    color: #409eff;
    font-size: 12px;
    margin-right: 20px;
}
    .remarks{
        // height: 50px;
        margin-bottom: 20px;
        >div{

        }
    }
}

</style>
