<template>
    <div class="plan">
            <History
                :title="`赴港计划：`"
                :content="option[planDetailData.plan_type-1]"
                :tabaData="planDetailData.edit"
                style="margin-bottom: 20px;"
            />
        <div v-for="(item, index) in planDetailData.question" :key="index" class="remarks">
            <div>
                <!-- 备注回显与编辑操作记录 -->
                <History
                    :title="`${item.title}：`"
                    :content="item.remark"
                    :tabaData="item.edit"
                />
                <!-- 添加附件 -->
                <div>
                    <span v-for="(item, index) in item.file" :key="index">
                        <a v-if="item.fileList.length" type="text" target='_blank' :href="`${$utils.toOss(item.fileList[0].fileUrl)}`">{{ item.name}}</a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import History from '../../C/History'
    export default {
        components: {
            History
        },
        props: {
            // 接收的数据
            planDetailData: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                option: ['就业', '创业', '先就业后创业']
            }
        },
    }
</script>

<style lang="less" scoped>
.plan{
    a{
    color: #409eff;
    font-size: 12px;
    margin-right: 20px;
}
    .remarks{
        >div{
            margin-bottom: 20px;
        }
    }
}

</style>
