<template>
    <div class="plan">
        <div class="remarks">
            <div style="display: flex;" >
                <div style="margin-top: 5px;">赴港计划：</div>
                <el-select v-model="editData.plan_type" size="small" placeholder="请选择">
                    <el-option label="就业" :value="1" />
                    <el-option label="创业" :value="2" />
                    <el-option label="先就业后创业" :value="3" />
                </el-select>
            </div>

            <div v-for="(item, index) in editData.question" :key="index" >
                <div>{{item.title}}：</div>
                <div style="margin-top: 10px;">
                    <el-input
                        type="textarea"
                        placeholder="请输入"
                        v-model="item.remark"
                        maxlength="2000"
                        show-word-limit
                    />
                </div>
                <!-- 添加附件 -->
                <AddAnnex
                    :echoFileData="item.file"
                    @confirm="addAnnexConfirm($event, index)"
                    @fileClose="fileClose($event, index)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import AddAnnex from '../../C/AddAnnex.vue'
import { planUpdate } from '@/api/schemeReview.js'
    export default {
        components: {
            AddAnnex
        },
        props: {
            // 接收的数据
            editPlanData: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                editData: {
                    plan_type: ''
                },
            }
        },
        mounted () {
            this.editData = this.editPlanData && JSON.parse(JSON.stringify(this.editPlanData))
        },
        methods: {
            // 添加附件确认后回传数据
            addAnnexConfirm (data, index) {
                console.log('this.editData',this.editData);
                this.editData.question[index].file = data
            },

            // 删除上传的附件
            fileClose(data ,i) {
                this.editData.question[i].file.forEach((item, j) => {
                    if (item.fileList[0] && item.fileList[0].fileUrl == data.fileList[0].fileUrl) {
                        this.editData.question[i].file.splice(j, 1)
                    }
                });
            },

            // 保存数据
            async saveInfo () {
                const params = {
                    order_id: this.$route.params.id,
                    ...this.editData,
                }
                const { code } = await planUpdate(params)
                if (code == 200) {
                    this.$message({
                        message: '编辑成功',
                        type: 'success'
                    });
                }
            }
        },
    }
</script>

<style lang="less" scoped>
.plan{
    a{
    color: #409eff;
    font-size: 12px;
    margin-right: 20px;
}
    .remarks{
        >div{
            margin-bottom: 20px;
        }
    }
}
</style>
