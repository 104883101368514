<template>
    <div>
        <el-popover
            v-if="tabaData && tabaData.length"
            placement="bottom"
            width="800"
            trigger="hover"
        >
            <div style="height: 250px; overflow: auto;">
                <el-table :data="tabaData" border >
                    <el-table-column property="after_remark" label="修改结果" ></el-table-column>
                    <el-table-column width="150" property="operation" label="操作人"></el-table-column>
                    <el-table-column width="150" property="created_at" label="修改时间"></el-table-column>
                </el-table>
            </div>

            <div slot="reference" class="title">
                <div>
                    <span>{{ title }}</span>
                    <span :style="{ color: contentColor }">{{ content }}</span>
                </div>
            </div>
        </el-popover>

        <div class="title" v-else>
            <span>{{ title }}</span>
            <span :style="{color: contentColor}">
                {{ content }}
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            // table数据
            tabaData: {
                type:Array,
                default: () => []
            },
            // 标题
            title: {
                type: String,
                default: ''
            },
            // 内容
            content: {
                type: String,
                default: ''
            },
            // 内容颜色
            contentColor: {
                type: String,
                default: '#000000'
            },
        },
        data() {
            return {
            };
        }
    }
</script>

<style lang="less" scoped>
.title{
    word-wrap:break-word;
    word-break:break-all;
}
</style>
