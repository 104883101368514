<!--
 * @Author: Leo
 * @Description: 通知提醒
-->
<template>
    <div>
        <el-dialog
            title="通知提醒"
            :visible.sync="dialogVisible"
            width="500px"
            :before-close="handleClose"
        >
            <div>
                <div>是否通知 {{ operation.operation }} 查看</div>
                <el-input
                    style="margin-top: 20px;"
                    type="textarea"
                    placeholder="请输入通知内容"
                    v-model="content"
                    maxlength="50"
                    show-word-limit
                />
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">关 闭</el-button>
                <el-button type="primary" :loading="isLoading" @click="confirm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { schemeReviewNotice } from '@/api/schemeReview.js'
    export default {
        props:{
            // 接收的数据
            operation: {
                type: Object,
                default: () => {}
            },
            // 模块名称
            component: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                dialogVisible: false,
                content: '',
                isLoading: false,
            }
        },
        updated() {
            console.log('operation', this.operation);
        },
        methods: {
            // 确认事件-将修改后的数据带回父组件更新回显的数据列表
            async confirm () {
                this.isLoading = true
                const params = {
                    order_id: this.$route.params.id,
                    content: this.content,
                    component: this.component
                }
                const { code } = await schemeReviewNotice(params)
                if (code == 200) {
                    this.$message({
                        message: '通知成功',
                        type: 'success'
                    });
                    this.content = ''
                    this.isLoading = false
                }
                this.dialogVisible = false
            },
            // 弹窗关闭事件
            handleClose() {
                this.content = ''
                this.dialogVisible = false
            },
        },
    }
</script>

<style lang="less" scoped>

</style>
