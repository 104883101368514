<template>
    <div class="provision">
        <div class="remarks">
            <div>
                <div style="margin-top: 10px;">
                    <el-input
                        type="textarea"
                        placeholder="请输入"
                        v-model="editData.remark"
                        maxlength="2000"
                        show-word-limit
                    />
                </div>
                <!-- 添加附件 -->
                <AddAnnex
                    :echoFileData="editData.file"
                    @confirm="addAnnexConfirm"
                    @fileClose="fileClose"
                />
            </div>
        </div>
    </div>
</template>

<script>
import AddAnnex from '../../C/AddAnnex.vue'
import { completeUpdate } from '@/api/schemeReview.js'
    export default {
        components: {
            AddAnnex
        },
        props: {
            // 接收的数据
            editCompleteData: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                editData: {},
            }
        },
        mounted () {
            this.editData = this.editCompleteData && JSON.parse(JSON.stringify(this.editCompleteData))
        },
        methods: {
            // 添加附件确认后回传数据
            addAnnexConfirm (data) {
                this.editData.file = data
            },

            // 删除上传的附件
            fileClose(data) {
                this.editData.file.forEach((item, i) => {
                    if (item.fileList[0].fileUrl == data.fileList[0].fileUrl) {
                        this.editData.file.splice(i, 1)
                    }
                });
            },

            // 保存数据
            async saveInfo () {
                const params = {
                    order_id: this.$route.params.id,
                    ...this.editData,
                }
                const { code } = await completeUpdate(params)
                if (code == 200) {
                    this.$message({
                        message: '编辑成功',
                        type: 'success'
                    });
                }
            }

        },
    }
</script>

<style lang="less" scoped>
.provision{
    a{
    color: #409eff;
    font-size: 12px;
    margin-right: 20px;
}
    .remarks{
        >div{
            margin-bottom: 20px;
        }
    }
}
</style>
