import api from './axios';

//获取用户菜单及按钮的设置
export const getUserMenu = (params) => api.get(`/administrator/user/menu`, {params})

// 优才在线方案审案列表接口
export const getSchemeReview = (params) => api.get('/business/scheme-review/get-info', {params})

// 发送企业微信通知
export const schemeReviewNotice  = (params) => api.post(`/business/scheme-review/notice`, params)

// 编辑/保存基本信息模块
export const editBasic = (params) => api.post(`/business/scheme-review/edit-basic`, params)

// 编辑/保存基本信息模块
export const editScoring = (params) => api.post(`/business/scheme-review/edit-scoring`, params)

// 编辑/保存资料提供情况
export const editProvision = (params) => api.post(`/business/scheme-review/edit-provision`, params)

// 编辑/保存文书方案
export const editDocument = (params) => api.post(`/business/scheme-review/edit-document`, params)

// 新增推荐信
export const letterAdd = (params) => api.post(`/business/letter/update`, params)

// 赴港计划编辑
export const planUpdate = (params) => api.post(`/business/plan/update`, params)

// 学位方案编辑
export const degreeUpdate = (params) => api.post(`/business/degree/update`, params)

// 编辑线上递交问题
export const onlineUpdate = (params) => api.post(`/business/online/update`, params)

// 全套终审编辑
export const completeUpdate = (params) => api.post(`/business/complete/update`, params)
