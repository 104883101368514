<template>
    <div class="letter">
        <div v-for="(item, index) in editData" :key="index" class="remarks">
            <div style="margin-top: 20px;">
                <div class="delete">
                    <div>{{item.title}}：</div>
                    <!-- <div><el-button type="text" @click="deleteFn(index)"> 删除</el-button></div> -->
                </div>
                <div style="margin-top: 10px;">
                    <el-input
                        type="textarea"
                        placeholder="请输入"
                        v-model="item.remark"
                        maxlength="2000"
                        show-word-limit
                    />
                </div>
                <!-- 添加附件 -->
                <AddAnnex
                    :echoFileData="item.file"
                    @confirm="addAnnexConfirm($event, index)"
                    @fileClose="fileClose($event, index)"
                />
            </div>
        </div>
        <el-button type="primary" icon="el-icon-plus" @click="add"> 添加</el-button>
    </div>
</template>

<script>
import AddAnnex from '../../C/AddAnnex.vue'
import { letterAdd } from '@/api/schemeReview.js'

    export default {
        components: {
            AddAnnex
        },
        props: {
            // 接收的数据
            editLetterData: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                editData: [],
            }
        },
        mounted () {
            this.editData = this.editLetterData && JSON.parse(JSON.stringify(this.editLetterData))
        },
        methods: {
            // 添加附件确认后回传数据
            addAnnexConfirm (data,index) {
                this.editData[index].file = data
            },

            // 删除上传的附件
            fileClose(data ,i) {
                this.editData[i].file.forEach((item, j) => {
                    if (item.fileList[0] && item.fileList[0].fileUrl == data.fileList[0].fileUrl) {
                        this.editData[i].file.splice(j, 1)
                    }
                });
            },
            // 添加
            add () {
                if (this.editData.length > 9) return this.$message({message: '最多支持添加10条数据',type: 'warning'});
                this.editData.push({
                    letter_id: this.editData.length + 1,
                    file: [],
                    edit: [],
                    remark: "",
                    title: `同行推荐信${this.editData.length + 1}`

                },)
            },
            // 删除
            deleteFn (index) {
                this.editData.splice(index, 1)
            },

            // 保存数据
            async saveInfo () {
                const params = {
                    order_id: this.$route.params.id,
                    letter: []
                }

                this.editData.forEach(item => {
                    params.letter.push(item)
                })


                console.log('params',params);
                const { code } = await letterAdd(params)
                if (code == 200) {
                    this.$message({
                        message: '编辑成功',
                        type: 'success'
                    });
                }
            }
        },
    }
</script>

<style lang="less" scoped>
.letter{
    a{
    color: #409eff;
    font-size: 12px;
    margin-right: 20px;
}
    .remarks{
        >div{
            margin-bottom: 20px;
        }
    }
    .delete{
        display: flex;
        justify-content: space-between;
    }
}
</style>
