<template>
    <div class="provision">
        <div class="remarks">
            <div>
                <!-- 备注回显与编辑操作记录 -->
                <div style="text-align: center;margin-top: 15px; line-height: 15px; height: 15px;" v-if="provisionDetailData.remark == ''">暂无数据</div>
                <History
                    v-else
                    title="备注/待确认信息："
                    :content="provisionDetailData.remark"
                    :tabaData="provisionDetailData.edit"
                />
                <!-- 添加附件 -->
                <div >
                    <span v-for="(item, index) in provisionDetailData.file" :key="index">
                        <a v-if="item.fileList.length" type="text" target='_blank' :href="`${$utils.toOss(item.fileList[0].fileUrl)}`">{{ item.name }}</a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import History from '../../C/History'
    export default {
        components: {
            History
        },
        props: {
            // 接收的数据
            provisionDetailData: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
            }
        },
    }
</script>

<style lang="less" scoped>
.provision{
    a{
    color: #409eff;
    font-size: 12px;
    margin-right: 20px;
}
    .remarks{
        >div{
            margin-bottom: 20px;
        }
    }
}

</style>
