<!--
 * @Author: Leo
 * @Description: 方案审核 基本信息编辑
-->
<template>
    <div class="basic">
        <!-- 基本信息 -->
        <div class="basic-info">
            <div>主申姓名：{{ basicDetailData.information_name }}</div>
            <div>文案：{{ basicDetailData.copywriters }}</div>
            <div>审案：{{ basicDetailData.chief_copywriter }}</div>
        </div>

        <!-- 备注内容 -->
        <div>
            <History
                title="其他特殊/紧急情况备注："
                :content="basicDetailData.remark"
                :tabaData="basicDetailData.edit"
                contentColor="red"
            />
            <!-- 添加附件 -->

            <div>
                <span v-for="(item, index) in basicDetailData.file" :key="index">
                    <a v-if="item.fileList.length" target='_blank' type="text" :href="`${$utils.toOss(item.fileList[0].fileUrl)}`">{{ item.name }}</a>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import History from '../../C/History'
    export default {
        components: { History },
        props: {
            // 接收的数据
            basicDetailData: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                // 编辑数据
                historyData: [],
            }
        },
    }
</script>

<style lang="less" scoped>
.basic{
    padding: 15px 25px;
    >div {
        margin-bottom: 20px;
    }
    .basic-info{
        display: flex;
        justify-content: space-between;
        >div{
            width: 220px;
            overflow:hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
a{
    color: #409eff;
    font-size: 12px;
    margin-right: 20px;
}
</style>
