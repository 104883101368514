<!--
 * @Author: Leo
 * @Description: 添加附件弹窗
-->
<template>
    <div>
        <div>
            <!-- 回显添加成功的附件数据，确认后才能回显 -->
            <span v-for="(item, index) in echoFileData" :key="index">
                <a v-if="item.fileList.length" target='_blank' type="text" :href="`${$utils.toOss(item.fileList[0].fileUrl)}`">{{ item.name }}</a>
                <i v-if="item.fileList.length" @click="fileClose(item)" class="el-icon-close fileClose"></i>
            </span>
            <el-button type="text" @click="dialogVisible = true">添加附件</el-button>
        </div>

        <!-- 弹窗 -->
        <el-dialog
            ref="form"
            title="添加附件"
            :visible.sync="dialogVisible"
            width="600px"
            :before-close="handleClose"
            :close-on-click-modal="false"
        >

            <el-form :model="ruleForm" ref="form" class="annexDialog" label-width="100px">
                <div v-for="(item, index) in ruleForm.annexList" :key="index" style="margin-bottom: 50px;">
                    <el-form-item label="附件名称：" :prop="'annexList.' + index + '.name'" :rules="rules.name">
                        <el-input
                            style="width: 300px;"
                            v-model="item.name"
                            placeholder="请输入"
                            maxlength="40"
                            show-word-limit
                        />
                        <span style="margin-left: 10px;">
                            <el-button v-if="index == 0" type="text" @click="addFile">添加文件</el-button>
                            <el-button type="text" @click="delFile(index)">删除</el-button>
                        </span>
                    </el-form-item>
                    <el-form-item :label="`附件${index + 1}：`">
                        <UploadFile
                            file-names="SchemeReview"
                            :multiple="false"
                            :idx="index"
                            :file-list="item.fileList"
                            :is-show-file="true"
                            file-list-name="fileName"
                            file-list-url="fileUrl"
                            :limit="1"
                            :file-type="[]"
                            :format-tip="['jpeg','jpg','png','txt','doc','docx','ppt','pptx','xls','xlsx','pdf', 'gif']"
                            @fileList="onChangeFile"
                            :maxSize="20"
                        />
                    </el-form-item>
                </div>
            </el-form>

            <div style="font-size: 12px; margin-left: 27px; margin-top: 25px;">仅支持小于20M的jpg、jpeg、png、gif、pdf、word、excel文件</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="confirm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        props: {
            // 回显列表数据
            echoFileData: {
                type: Array,
                default: () => []
            },
        },
        data() {
            return {
                dialogVisible: false,

                rules: {
                    name: [
                        { required: true, message: '请输入附件名称', trigger: 'change' },
                        { max: 40, message: '长度为40个字符', trigger: 'change' }
                    ],
                },
                ruleForm: {
                    // 循环的列表
                    annexList: [],
                }
            };
        },
        mounted() {
        },
        watch: {
            dialogVisible (val) {
                setTimeout( ()=>{
                    this.$refs['form'].clearValidate()
                    if(val && this.echoFileData.length) {
                    this.ruleForm.annexList = this.echoFileData && JSON.parse(JSON.stringify(this.echoFileData))
                    } else {
                        this.ruleForm.annexList = [{
                            name: '',
                            fileList: []
                        }]
                    }
                },200)
            }
        },

        methods: {
            // 添加文件
            addFile () {
                if (this.ruleForm.annexList.length > 9) {
                    this.$message({
                        message: '最多支持上传10个文件',
                        type: 'warning'
                    });
                    return
                }

                this.ruleForm.annexList.push({
                    name: '',
                    fileList: []
                })
            },
            // 弹窗外删除文件
            fileClose (item) {
                this.$emit('fileClose',item)
            },

            // 删除文件
            delFile (index) {
                this.ruleForm.annexList.splice(index, 1)
            },
            // 上传列表变化
            onChangeFile(list, idx) {
                this.ruleForm.annexList[idx].name = list[0].fileName
                this.ruleForm.annexList[idx].fileList = list
            },
            // 确认事件-将修改后的数据带回父组件更新回显的数据列表
            confirm () {
                let file = false
                this.ruleForm.annexList.forEach(item => {
                    if (item.fileList.length == 0) {
                        file = true
                    }
                })
                if (file) return this.$message({message: '请上传附件',type: 'warning'});
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$emit('confirm', this.ruleForm.annexList)
                        this.dialogVisible = false
                    } else {
                        this.$message({message: '输入有误，请重新输入',type: 'warning'})
                        return false;
                    }
                })
            },
            // 弹窗关闭事件
            handleClose() {
                this.dialogVisible = false
            },
        }
    }
</script>

<style lang="less" scoped>
.annexDialog{
    overflow: auto;
    max-height: 500px;

    // 滚动条的样式,宽高分别对应横竖滚动条的尺寸
    &::-webkit-scrollbar {
        width: 3px;
    }

        // 滚动条里面默认的小方块,自定义样式
        &::-webkit-scrollbar-thumb {
        // background: #8798AF;
        border-radius: 2px;
    }

// 滚动条里面的轨道
    &::-webkit-scrollbar-track {
        background: transparent;
    }
}

a{
    color: #409eff;
    font-size: 12px;
}
.fileClose{
    margin-left: 3px;
    margin-right: 20px;
    font-size: 12px;
    cursor: pointer;
}
.fileClose:hover{
    background: #dcdfe6;
    border-radius: 100%;
    text-align: center;
}

</style>
