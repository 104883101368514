<template>
    <div v-loading="isLoading">
        <Container title="打分情况" padding="0">
            <div>
                <div class="title-box">
                    <span class="title">打分情况</span>
                    <div class="title-btn">
                        <el-button v-if="!isEdit" v-auth="'SchemeReview-Gifted-other'" @click="isEdit = true">编辑</el-button>
                        <el-button v-if="isEdit" @click="cancel">取消</el-button>
                        <el-button v-if="isEdit" :loading="isLoading" @click="saveInfo">保存</el-button>
                    </div>
                </div>
                <!-- 回显数据 -->
                <ScoringDetail v-if="!isEdit" class="padding" :scoringDetailData="currentData"/>
                <!-- 编辑 -->
                <EditScoring v-if="isEdit" ref="EditScoring" class="padding"  :editScoringData="currentData"/>
            </div>
        </Container>
        <!-- 通知提醒 -->
        <Inform  ref="Inform" :operation="currentData" component="scoring"/>
    </div>
</template>

<script>
import ScoringDetail from './C/ScoringDetail'
import EditScoring from './C/EditScoring'
import Inform from '../C/Inform.vue'
    export default {
        components: {
            ScoringDetail,
            EditScoring,
            Inform
        },
        props: {
            // 接收的数据
            dataDetail: {
                type: Object,
                default: () => {}
            },
        },
        data() {
            return {
                isEdit: false,
                isLoading: false,
                currentData: {} // 编辑与回显的数据
            }
        },
        watch: {
            dataDetail () {
                // 保存后，重新刷新列表时，重新赋值
                this.currentData = this.dataDetail && JSON.parse(JSON.stringify(this.dataDetail))
            }
        },
        created () {
            // 不影响初始数据
            this.currentData = this.dataDetail && JSON.parse(JSON.stringify(this.dataDetail))
        },
        methods: {
            // 取消事件
            cancel () {
                this.isEdit = false
            },
            // 保存事件
            async saveInfo () {
                // this.isLoading = true
                // 保持vue数据的单向传输，当前父组件的数据带给子组件，在子组件完成修改后。再回传重新赋值更新所有数据
                await this.$refs['EditScoring'].saveInfo()
                await this.$parent.getData()
                this.isEdit = false
                this.isLoading = false
                this.$refs['Inform'].dialogVisible = true
            },
        },
    }
</script>

<style lang="less" scoped>
.padding{
    padding: 15px 25px;
}
/deep/ .card .upper-right{
    display: none;
}
.title-box{
    .title{
        color: #fff;
    }
    background: #409eff;
}
</style>
